import { isSetCourseLoaded } from './../core/store/index';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, OnInit, HostBinding, HostListener, Inject, ViewChild, ViewContainerRef, AfterViewInit, ChangeDetectorRef, AfterContentChecked, OnDestroy, ElementRef } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { HttpClient } from '@angular/common/http';
import { ElectronService } from '../core/services/electron.service';
import { TranslateService } from '@ngx-translate/core';
import { AccountService } from '../core/services/account.service';
import {AppConfig, POSTHOG_API_KEY, POSTHOG_APP_URL, API_DIR, APP_ID} from '../environments/environment';
import { AuthService } from '../core/services/auth.service';
import { AuthGuard } from '../core/services/auth-guard.service';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError, ActivatedRoute } from '@angular/router';
import { CourseService } from '../core/services/courses.service';
import { ListenerService } from '../core/services/listener.service';
import { SettingsService } from '../core/services/new-settings.service';
import { switchMap, take, takeUntil, filter, tap, withLatestFrom, distinctUntilChanged, map, takeWhile } from 'rxjs/operators';
import { BehaviorSubject, fromEvent, Subject, combineLatest, pipe, interval } from 'rxjs';
import { ActivityHeaderService } from '../core/services/activity-header.service';
import { AddCourseDialogComponent } from '../core/components/add-course-dialog/add-course-dialog.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CdkDragEnter, moveItemInArray, CdkDragDrop } from '@angular/cdk/drag-drop';
import { SubscriptionService } from '../core/services/subscription.service';
import { PlanService } from '../core/services/plan.service';
import { NavbarListenerService } from '../core/services/navbar-listener-service..service';
import { DataService } from '../core/services/data.service';
// import {setInterval, clearInterval} from 'timers';
import { OverlayContainer } from '@angular/cdk/overlay';
import {WorkbooksService} from '../core/services/workbooks.service';
import {UtilityService} from '../core/services/utility.service';
import {Socket} from 'ngx-socket-io';
import {LessonPlanWarningDialogComponent} from '../core/components/lesson-plan-warning-dialog/lesson-plan-warning-dialog.component';
import {SimultaneousLoginDialogComponent} from '../core/components/simultaneous-login-dialog/simultaneous-login-dialog.component';
import {TextBoxComponent} from '../activities/activity-header/text-box/text-box.component';
import * as planActions from '../core/store/plan/plan.actions';
import * as CoursesActions from '../core/store/courses/courses.actions';
import * as SetCourseActions from '../core/store/set-course/set-course.actions';
import * as SubscriptionsActions from '../core/store/subscriptions/subscriptions.actions';
import { Store, select } from '@ngrx/store';
import * as reducers from '../core/store';
import { reducer } from '../core/store/dummy/dummy-reducer';
import * as SettingsActions from '../core/store/settings/settings.actions';
import { GetWhizzimoLibraryWorkbooks } from '../core/store/workbook/workbook.actions';
import { selectGetAllSettings, getCurrentSetting } from '../core/store';

import {TileActivityService} from '../core/services/tile-activity.service';
import { GetAllActivityTypes } from '../core/store/activity/activity.actions';
import { ClearCourseWorkbooks } from '../core/store/course-workbook/course-workbook.actions';
import { ActivityCanvasService } from '../fabric-canvas/services/activity-canvas.service';
import {UserIdleService} from 'angular-user-idle';
import { MatDrawer } from '@angular/material/sidenav';

// import {RollbarErrorHandler} from './components/ErrorHandlers/RollbarErrorHandler';
// import {MixpanelService} from './MixpanelService';

interface TreeNode {
  name: string;
  children?: TreeNode[];
  path?: string;
  parent?: string;
  order?: number;
  teacherKey?: number;
  subscriptionId?: string;
}

const TREE_DATA: TreeNode[] = [
  {
    name: 'Courses',
    children: [
      {
        name: 'Add a Course',
        parent: 'Courses'
      }
    ]
  },
  {
    name: 'Settings',
    children: [
      {
        name: 'General',
        parent: 'Settings',
        path: 'general'
      },
      {
        name: 'Tile',
        parent: 'Settings',
        path: 'tiles'
      },
      {
        name: 'Blackboard',
        parent: 'Settings',
        path: 'blackboard'
      },
      {
        name: 'Workbook Tiles',
        parent: 'Settings',
        path: 'workbook-tiles'
      },
      {
        name: 'Mini Tile Board',
        parent: 'Settings',
        path: 'mini-tiles'
      },

      {
        name: 'Wordcards',
        parent: 'Settings',
        path: 'wordcards'
      },
      {
        name: 'Timed Reading',
        parent: 'Settings',
        path: 'timed-reading'
      },
      {
        name: 'Flashcards',
        parent: 'Settings',
        path: 'flashcards'
      },
      {
        name: 'Lettercards',
        parent: 'Settings',
        path: 'lettercards'
      },
      {
        name: 'Sentences & More',
        parent: 'Settings',
        path: 'sentence-parts'
      },
      {
        name: 'Spelling',
        parent: 'Settings',
        path: 'spelling'
      },

      {
        name: 'Whiteboard',
        parent: 'Settings',
        path: 'whiteboard'
      },
      {
        name: 'Blank Tiles',
        parent: 'Settings',
        path: 'blank-tiles'
      },
      {
        name: 'PDF Viewer',
        parent: 'Settings',
        path: 'pdf-viewer'
      },
      {
        name: 'Advanced',
        parent: 'Settings',
        path: 'advanced'
      },

    ]
  },
  {
    name: 'Sharing',
    children: []
  },
  {
    name: 'Help',
    children: [
      {
        name: 'Tutorial Videos',
        parent: 'Help'

      }, {
        name: 'Support Center',
        parent: 'Help'
      },
    ]
  },

];

/** Flat node with expandable and level information */
interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  level: number;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [
    './app.component.scss',
    '../assets/scss/fontawesome.scss',
    '../assets/scss/brands.scss',
    '../assets/scss/regular.scss',
    '../assets/scss/solid.scss',
  ]
})
export class AppComponent implements OnInit, AfterViewInit, AfterContentChecked, OnDestroy {
  @ViewChild('drawer', { static: false}) drawer: MatDrawer;

  constructor(
    public electronService: ElectronService,
    private translate: TranslateService,
    private _auth: AuthService,
    private _guard: AuthGuard,
    private courseService: CourseService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private listnerService: ListenerService,
    private activityHeaderService: ActivityHeaderService,
    private subscriptionService: SubscriptionService,
    private planService: PlanService,
    public dataService: DataService,
    private accountService: AccountService,
    private settingsService: SettingsService,
    private navbarListenerService: NavbarListenerService,
    private workbooksService: WorkbooksService,
    public overlayContainer: OverlayContainer,
    private utilityService: UtilityService,
    private socket: Socket,
    private store: Store,
    private cdref: ChangeDetectorRef,
    private tileActivityService: TileActivityService,
    private elementRef: ElementRef,
    private activityCanvasService: ActivityCanvasService,
    private userIdle: UserIdleService,
    private http: HttpClient,
    // private mixPanelService: MixpanelService
  ) {

    (<any>window).Intercom("boot", {
      app_id: APP_ID,
    })
    this.listnerService.listener().pipe(takeUntil(this.unsubscribe$)).subscribe((data: any) => {
      if (data === 'Tile Position Changed') {
      }
    });
    let eventRouter;
    this.isMobile = this.utilityService.mobileCheck();
    this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd),
      switchMap(event => {
        eventRouter = event;
        return this.settingsService.checkVersion();
      }),
      takeUntil(this.unsubscribe$)
    ).subscribe((version) => {
      if (eventRouter?.id && version) {
        if (eventRouter.id === 1) {
          localStorage.setItem('version', JSON.stringify(version));
        } else {
          let localVersion = +(localStorage.getItem('version'));
          if (localVersion !== +version) {
            localStorage.setItem('version', JSON.stringify(version));
            window.location.reload();
          }
        }
      }
    });
    this.router.events.pipe(takeUntil(this.unsubscribe$)).subscribe((event) => {
      if (event instanceof NavigationEnd && this.router.url !== '/account' && router.url !== '/registration' && router.url !== '/login' && router.url !== '/passwordreset') {
        if (!this.socketConnected) {
          this.socketInit();
        }
        if (this.router.url.split('/').includes('activity')) { this.activeChildNode = null; }

        setTimeout(() => {
          if (this.selectedPlan === '' || this.profile.subscriptions.data[0] && this.profile.subscriptions.data[0].status.toLowerCase() === 'past_due') {
            this.selectPlanModal();
          }
        }, 2500);
      }
      if (event instanceof NavigationEnd && this.router.url === '/account') {
        this.accountShow = true;
      }
      if (event instanceof NavigationEnd && event.url) {
        // mixPanelService.trackEvent("page view", event.url, `${this.firstname} ${this.lastname}`, this.profile?.email);

        if (
          event.url.includes('edit-lesson-plan') ||
          event.url.includes('workbook-builder') ||
          event.url.includes('library;libraryType') ||
          event.url.includes('activity/whiteboard') ||
          event.url.includes('activity/blackboard') ||
          event.url.includes('activity/pdf-viewer')
        ) {
          this.wholePageOverflowHidden = true;
        } else {
          this.wholePageOverflowHidden = false;
        }

      }
    });
    this.treeFlattener = new MatTreeFlattener(this._transformer, node => node.level, node => node.expandable, node => node.children);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    // this.dataSource.data = TREE_DATA;
    this.showAllCourses = false;
    this.listnerService.listener().pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
      if (res === 'course added' || res === 'duplicated') {
        // TODO: to be removed


        // this.showAllCourses = true;
        // this.store.select(reducers.getAllNonAndSubscriptionCourses).subscribe((response: any) => {
        //   this.courses = response;
        //   localStorage.setItem('currentCourseId', response[response.length - 1]._id);
        //   this.updateCourse(response[response.length - 1]._id);
        //   this.router.navigateByUrl('my-curriculum');
        // });
        // this.getAllCourses();
      } else if (res === 'body') {
        ActivityHeaderService.body = this.body;
        this.listnerService.callback('body retrieved');
      }
    });
    this._guard.loginStatusListener().pipe(takeUntil(this.unsubscribe$)).subscribe((status: boolean) => {
      this.loginStatus = status;
      if (status) {
        this.init();
      }
    });

    // Get token from clever auth0 middleware
    const get_id_token = window.location.hash.match(/id_token=[^&]*/i);
    const get_access = window.location.hash.match(/access=[^&]*/i);

    if (get_access) {
      const accessToken = get_access[0].substring(get_access[0].indexOf("=") + 1);
      const idToken = get_id_token[0].substring(get_id_token[0].indexOf("=") + 1);
      const authRes = {
            accessToken: accessToken,
            idToken: idToken,
            expiresIn: '7200'
          };
      // Call auth for Clever
      _auth.handleAuthenticationClever(authRes);
    } else {
      // this._guard.checkLogin();

      translate.setDefaultLang('en');

      if (electronService.isElectron()) {
      } else {
      }
      _auth.handleAuthentication();
      _auth.handleAuthenticationWithHash();
    }
  }
  @ViewChild('body', { read: ViewContainerRef, static: false }) public body: ViewContainerRef;
  @ViewChild('matAccordion') matAccordion: ElementRef;
  public firstname: string;
  public lastname: string;
  public showDemoModal = true;
  public username: any;
  public showSidePanel: boolean;
  isMobile = false;
  public loginStatus: boolean;

  themehighlightcolor: any;
  logoImage: any;
  accountarrowImage: any;

  private socketConnected = false;

  public previousUrl: any;
  public currentUrl: any;

  private unsubscribe$: Subject<void> = new Subject();
  hasScroll: boolean;
  public path: string;
  public showAllCourses: boolean;
  profile: any;
  activeChildNode: any;
  leftPanelShow = false;
  treeControl = new FlatTreeControl<ExampleFlatNode>(node => node.level, node => node.expandable);
  treeFlattener: any;
  dataSource: any;
  userId: any;
  courses: any[] = [];
  selectedNode: string;

  disabled = false;
  compact = false;
  images: any;
  selectedPlan = '';
  sideNavIcon = [];
  // sideNavIcon = [
  //   {
  //     active: _variable_images + 'courses-active-1.png',
  //     inActive: _variable_images + 'courses-1.png',
  //     name: 'Courses'
  //   },
  //   {
  //     active: _variable_images + 'setting-active-1.png',
  //     inActive: _variable_images + 'setting-1.png',
  //     name: 'Settings'
  //   },
  //   {
  //     active: _variable_images + 'sharing-active-1.png',
  //     inActive: _variable_images + 'sharing-1.png',
  //     name: 'Sharing',
  //   },
  //   {
  //     active: _variable_images + 'help-active-1.png',
  //     inActive: _variable_images + 'help-1.png',
  //     name: 'Help'
  //   }
  // ];
  shown: 'native' | 'hover' | 'always' = 'native';
  activeParentNode: any;
  accountShow = false;
  loadingCount = 0;
  indexExpanded: number;
  addCircleImage: any;
  moveNavDropdownImage: any;
  moveNavDropdownImageAlt: any;
  activeCourse: any;

  currentTheme = 'light-theme';
  @HostBinding('class') componentCssClass;

  wholePageOverflowHidden: boolean;


  private _transformer = (node: TreeNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
      parent: node.parent,
      path: node.path
    };
  }
  async socketInit() {
    if (!localStorage.profile) {
      return;
    }

    const userId = JSON.parse(localStorage.profile).user_metadata.uid;

    this.socket.on('login', (msg: any) => {
      if (msg.userId === userId && msg.access_token !== localStorage.access_token) {
        this.dialog
          .open(SimultaneousLoginDialogComponent, {
            panelClass: 'small-dialog',
            minWidth: 350
          })
          .afterClosed().subscribe(() => {
            this.logout();
          });
      }
    });

    this.socket.emit('login', {access_token: localStorage.access_token, userId});

    this.socketConnected = true;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  ngOnInit() {
    this.userIdle.startWatching();
    this.userIdle.onTimerStart().pipe(takeUntil(this.unsubscribe$)).subscribe(count => {

      this.userIdle.stopTimer();
      this.userIdle.stopWatching();
      this.userIdle.startWatching();
      //only run the line below if the user is not on the /login page already (to prevent infinite loop)
      if (this.router.url !== '/login') {
        this.logout();
      }
    })
    
    let profile = JSON.parse(localStorage?.profile);

    if (!profile.email_verified) {
      this.utilityService.openVerificationEmailDialog();
    }

    if (!profile.user_metadata.tosAccepted) {
      this.utilityService.openTermsOfServiceDialogHelper();
    }

    this.router.events.pipe(takeUntil(this.unsubscribe$)).subscribe(event => {
      if (event instanceof NavigationEnd) {
        // Do something when the route starts to change

        if (localStorage.getItem('refresh') === 'true') {
          window.location.reload();
          localStorage.setItem('refresh', 'false');
        }
      }
    });
    // create an interval that calls a service every minute

    interval(60000)
      .pipe(switchMap(() => this.http.get(`${API_DIR}/version`)), takeUntil(this.unsubscribe$))
      .subscribe((version: any) => {
        if (!localStorage.getItem('appversion')) {
          localStorage.setItem('appversion', version.data);
          localStorage.setItem('refresh', 'true');
          return;
        }

        if (localStorage.getItem('appversion') != version.data) {
          localStorage.setItem('appversion', version.data.toString());
          localStorage.setItem('refresh', 'true');
        }
      });

    // localStorage.setItem('tileBankViewHeight', '35.00');
    localStorage.removeItem('tileBankViewHeight');
    this.store.select(reducers.selectCurrentTheme)
      .pipe(
        // distinctUntilChanged(),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(theme => {
        if (!theme) { return; }
        if (theme === 'theme-dark') {
          this.activityHeaderService.setMarkerColor('#C0C0C0');
        } else {
          this.activityHeaderService.setMarkerColor('#000');
          this.currentTheme = theme;
        }
      });

    this.store.select(reducers.getCurrentSetting)
      .pipe(
        filter(res => !!res),
        take(1)
      )
      .subscribe(res => {
        this.activityCanvasService.setFont(res.textfont);
        this.activityCanvasService.fontSize = res.fontSize;
      });

    this.store.select(reducers.selectGetPlan)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result: any) => {
        if (!result || !result.id) {
          return;
        }
        this.selectedPlan = result.name;
        this.listnerService.setPlan(result.name);

        // Added admin panel for staff
        if (this.selectedPlan === 'Staff') {
          TREE_DATA[1].children.unshift({
            name: 'Admin Panel',
            parent: 'Settings',
            path: 'admin-panel'
          });
        }

        if (this.showDemoModal && this.selectedPlan === 'Demo') {
          this.demoReminderModal();
          this.showDemoModal = false;
          const course = this.courses.find(course => !course?.subscriptionId)
          this.store.dispatch(new SetCourseActions.SetCurriculumCourse({
            course
          }))
        }
      });


    this.dataService._variable_images.pipe(takeUntil(this.unsubscribe$)).subscribe(path => {
      this.logoImage = path + 'logo.png';
      this.accountarrowImage = path + 'right@2x.png';
      // this.dataService.setTheme(path);
    });
    this.dataService._variable_images.pipe(takeUntil(this.unsubscribe$)).subscribe(path => {
      if (path === 'assets/img/theme1/') {
       // this.currentTheme = 'light-theme';
        // this.overlayContainer.getContainerElement().classList.add('light-theme');
        // this.componentCssClass = 'light-theme';
        if (document.body.classList.value) {
          const classList = document.body.classList.value.split(' ');
          document.body.classList.remove(...classList);
        }
        setTimeout(function() {
          document.body.classList.add("light-theme");
        }, 100);
      } else if (path === 'assets/img/theme-dark/') {
        if (document.body.classList.value) {
          const classList = document.body.classList.value.split(' ');
          // remove all classes from classList
          classList.forEach((className) => {
            document.body.classList.remove(className);
          });
        }
        // setTimeout(function() {
          document.body.classList.add("dark-theme");
        // }, 100);
      } else if (path === 'assets/img/theme-focus/') {
        if (document.body.classList.value) {
          const classList = document.body.classList.value.split(' ');
          // remove all classes from classList
          classList.forEach((className) => {
            document.body.classList.remove(className);
          });
        }
        // setTimeout(function() {
          document.body.classList.add("focus-theme");
        // }, 100);
      }
      this.addCircleImage = path + 'add-circle.png',
        this.moveNavDropdownImage = path + 'move.png',
        this.sideNavIcon = [
          {
            active: path + 'courses-active-1.png',
            inActive: path + 'courses-1.png',
            name: 'Courses'
          },
          {
            active: path + 'setting-active-1.png',
            inActive: path + 'setting-1.png',
            name: 'Settings'
          },
          {
            active: path + 'sharing-active-1.png',
            inActive: path + 'sharing-1.png',
            name: 'Sharing',
          },
          {
            active: path + 'help-active-1.png',
            inActive: path + 'help-1.png',
            name: 'Help'
          },
          {
            active: path + 'active-account.png',
            inActive: path + 'inactive-account.png',
            name: 'Account'
          }
        ];
    });

    this.navbarListenerService.navBarListner().pipe(takeUntil(this.unsubscribe$)).subscribe((res) => {
      this.leftPanelShow = res;
      if (res) {
        return this.drawer.close()
      }
      this.drawer.open();
      // this.activityHeaderService.callNavbarListner();
    });
    this.listnerService.listener().pipe(takeUntil(this.unsubscribe$)).subscribe((callback: any) => {
      const payload = callback.split(':');
      if (payload.length > 1 && payload[0] === 'course')  {
        let node = TREE_DATA[0].children.filter((child) => child.path === payload[1]);

        // this.handleNode(TREE_NODE, 0)
      }
    });
    let count = 0;
    // const interval = setInterval((function () {
    //   if (localStorage.profile || count >= 10) {
    //     this.init();
    //     clearInterval(interval);
    //   }
    //   count++;
    // }).bind(this), 500);

    this.store.select(reducers.selectCurrentTheme)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(theme => {
        if (theme === 'theme1') {
          this.moveNavDropdownImageAlt = 'assets/svgs/solid/move-modal.svg';
        } else {
          this.moveNavDropdownImageAlt = '';
        }

      });

    this.store.select(reducers.getSetCourseData)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(setCourseData => {
        if (!setCourseData) {
          return;
        }
        this.activeCourse = setCourseData;
        this.activeChildNode = this.activeCourse._id;
        if (this.checkUrlContains('my-curriculum') || this.checkUrlContains('activity') || this.checkUrlContains('workbook-builder')) {
          // this.activeChildNode = setCourseData?._id;
        }
      });

    if (localStorage?.profile) {
      this.store.dispatch(new planActions.GetPlan(JSON.parse(localStorage.profile).user_metadata.cusID));
    }
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  init() {
    this.showSidePanel = window.location.hash.indexOf('login') < 0;
    if (localStorage.profile || this.loginStatus) {
      // this.store.dispatch(new SubscriptionsActions.GetAllSubscribedCourses(JSON.parse(localStorage.profile).user_metadata.uid));
      this.setProfile();
      // this.workbooksService
      // .getLibraryWorkbooks()
      // .subscribe(workbooks => {
      //   localStorage.setItem('whizzimoLibrary', JSON.stringify(workbooks));
      // });
      this.store.dispatch(new GetWhizzimoLibraryWorkbooks(''));
      // this.settingsService.getAll(JSON.parse(localStorage.profile).user_metadata.uid)
      //   .subscribe((results: any) => {
      //     let defaultSettings;
      //     if (results.every((settings) => settings.isCurrent) || results.every((settings) => !settings.isCurrent)) {
      //       defaultSettings = results[0];
      //       defaultSettings.isCurrent = true;
      //     } else {
      //       defaultSettings = results.find((settings) => settings.isCurrent);
      //     }

      //     this.settingsService.updateSettings(defaultSettings);
      //     setTimeout(() => {
        // TODO: need to review
      //       this.dataService.setTheme('assets/img/' + defaultSettings.themeHighlightColor + '/');

      //     }, 500);


      //   });
      this.store.dispatch(new SettingsActions.GetAllSettings(JSON.parse(localStorage.profile).user_metadata.uid));
      this.store.select(getCurrentSetting).pipe(takeUntil(this.unsubscribe$)).subscribe((setting: any) => {
        if (setting) {
          this.dataService.setTheme('assets/img/' + setting.themeHighlightColor + '/');

        }

      });
      this.store.dispatch(new GetAllActivityTypes(''));
    }

    // this.addCircleImage = _variable_images + 'add-circle.png',
    //   this.moveNavDropdownImage = _variable_images + 'move.png',
    this.getAllCourses();

    this.listnerService.listener().pipe(takeUntil(this.unsubscribe$)).subscribe((callback) => {
      if (callback === "updateSideNav") {
        this.getAllCourses();
      } else if ( callback.split(':')[0] === 'selectedCourse') {
        let parts = callback.split(':');
        // this.loadingCount  = 0;
        this.getAllCourses();
        this.activeChildNode = parts[1];
        // this.dataSource.data.forEach((item) => {
        //   if (item.name === 'Courses') {
        //     this.selectedNode = item.name;
        //     if (item.children.length > 0) {
        //       this.activeChildNode = item.children[0].path;
        //     }
        //   }
        // });

      }
      // else
      // if (callback.split(':')[0] === "CopiedSideNav") {
      //   setTimeout(() => {
      //     const nodes = this.dataSource.data[0].children.filter((child: any) => {
      //       return !child.subscriptionId;
      //     });
      //     this.handleNode(nodes[nodes.length - 2]);
      //   }, 1300);
      // }
    });



    // this.settingsService
    // .getAll(JSON.parse(localStorage.profile).user_metadata.uid)
    // .subscribe((settings) => {
    //   this.settingsService.activitySettingsSubjet$ = new BehaviorSubject(settings[0])
    // })

    this.router.events.pipe(takeUntil(this.unsubscribe$)).subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        // Show loading indicator
        localStorage.setItem('navigatePath', event.url);
      }

      if (event instanceof NavigationEnd) {
        if (!this.profile) {
          this.setProfile();
        }

        this.showSidePanel = window.location.hash.indexOf('login') < 0;
        // Hide loading indicator
        this.path = event.url;
        this.setChildActive();
      }

      if (event instanceof NavigationError) {
        // Hide loading indicator
        // Present error to user
      }

    });
  }

  setProfile() {
    if (localStorage?.profile) {
      this.accountService.getPaymentAccount(JSON.parse(localStorage.profile).user_metadata.cusID).pipe(takeUntil(this.unsubscribe$)).subscribe((profile) => {
        this.profile = profile;
        console.log('profile loaded', profile, JSON.parse(localStorage.profile));
        const email = this.profile.email || 'no-email@whizzimo.com';
        this.firstname = this.profile.metadata['First Name'];
        this.lastname = this.profile.metadata['Last Name'];
        let intercomSettings = {
          created_at: this.profile.created ,
          app_id: APP_ID,
          email,
          name: `${this.firstname} ${this.lastname}`,
          user_id: this.profile.metadata.AuthKey,
          Plan_Cancelling: this.profile.subscriptions.data[0].cancel_at_period_end,
          Plan_Renewal_Date: this.profile.subscriptions.data[0].current_period_end,
          Plan_Start_Date: this.profile.subscriptions.data[0].current_period_start,
          Plane_Code: this.profile.subscriptions.data[0].plan.id,
          plan: this.selectedPlan,
          period_start: this.profile.nextInvoice?.period_start,
          period_end: this.profile.nextInvoice?.period_end,
          next_payment_attempt: this.profile.nextInvoice?.next_payment_attempt,
          amount_paid: this.profile.nextInvoice?.amount_paid,
          Amount_Due: this.profile.nextInvoice?.amount_due,
          "Referral Code Description": this.profile.metadata["Referral Code Description"],
          "Refferal Code": this.profile.metadata["Referral Code"],
          discount: this.profile.discount,
          logins_count: JSON.parse(localStorage.profile).logins_count,
          last_login: JSON.parse(localStorage.profile).last_login,
          widget: {
            "activator": "#intercom"
          }
        };

        // this.mixPanelService.identify(this.profile.metadata.AuthKey);
        // this.mixPanelService.setPeople({
        //   name: `${this.firstname} ${this.lastname}`,
        //   email,
        //   $email: email
        // });


        // use http to get intercom settings from backend using API_DIR
        this.http.get(API_DIR + '/user/intercom/' + this.userId)
        .pipe(take(1))
        .subscribe((res: any) => {
          intercomSettings = {...intercomSettings, ...res.data};

          (<any>window).Intercom('boot', {
            ...intercomSettings
          });
        });
        // this.mixPanelService.identify(this.profile.metadata.AuthKey);
        // this.mixPanelService.setPeople({
        //   name: `${this.firstname} ${this.lastname}`,
        //   email,
        //   $email: email
        // });
          // @ts-ignore
          // window.checksumai?.init(POSTHOG_API_KEY, {api_host: POSTHOG_APP_URL});

        // @ts-ignore
        // window.checksumai?.identify(email, {
        //   plan: this.profile.subscriptions.data[0]?.plan?.name
        // });
      });
    }
  }

  getAllCourses() {
    if (!localStorage.profile) {
      return;
    }

    this.userId = JSON.parse(localStorage.profile).user_metadata.uid;
    if (this.userId === undefined) {
      return;
    }
    combineLatest([this.store.select(reducers.selectGetAllSubscription), this.store.select(reducers.selectGetAllCourses)])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((allCourses: any) => {
        const sortedCourses = allCourses[1];
        const sortedSubscribedCourses = allCourses[0];

        let newSortedSubscribedCourses = Array.from(new Set(sortedSubscribedCourses.map(course => course._id)))
        .map(id => {
          return sortedSubscribedCourses.find(course => course._id === id);
        });
        if (this.isMobile) {
          newSortedSubscribedCourses = newSortedSubscribedCourses.filter((course) => {
            return !course.courseName.split(' ').includes('Barton');
          });
        }

        // const courses = sortedCourses.concat(newSortedSubscribedCourses);
        const courses = newSortedSubscribedCourses.concat(sortedCourses);
        this.courses = courses;
        // TREE_DATA[0].children.splice(1);
        TREE_DATA[0].children = [{
          name: 'Add a Course',
          parent: 'Courses'
        }];
        let tmpCourses = [];
        let tmpSubscriptions = [];
        if (this.showAllCourses) {
          courses.forEach((course) => {
            // TREE_DATA[0].children.push({ name: course.courseName, parent: 'Courses', path: course._id, order: course.order, teacherKey: course.teacherKey, subscriptionId: course.subscriptionId });
            const dataItem = { name: course.courseName, parent: 'Courses', path: course._id, order: course.order, teacherKey: course.teacherKey, subscriptionId: course.subscriptionId };
            if (course.subscriptionId) {
              tmpSubscriptions.push(dataItem);
            } else {
              tmpCourses.push(dataItem);
            }
          });
          TREE_DATA[0].children = tmpSubscriptions.concat(TREE_DATA[0].children);
          TREE_DATA[0].children = TREE_DATA[0].children.concat(tmpCourses);
        } else {
          if (courses.length >= 8) {
            // const newCourses = courses.slice(0, 8);
            const newCourses = [...courses];
            newCourses.forEach((course) => {
              // TREE_DATA[0].children.push({ name: course.courseName, parent: 'Courses', path: course._id, order: course.order,
              // teacherKey: course.teacherKey, subscriptionId: course.subscriptionId });
              const dataItem = { name: course.courseName, parent: 'Courses', path: course._id, order: course.order, teacherKey: course.teacherKey, subscriptionId: course.subscriptionId };
              if (course.subscriptionId) {
                tmpSubscriptions.push(dataItem);
              } else {
                tmpCourses.push(dataItem);
              }
              });
          } else {
            courses.forEach((course) => {
              // TREE_DATA[0].children.push({ name: course.courseName, parent: 'Courses', path: course._id, order: course.order,
              // teacherKey: course.teacherKey, subscriptionId: course.subscriptionId });
              const dataItem = { name: course.courseName, parent: 'Courses', path: course._id, order: course.order, teacherKey: course.teacherKey, subscriptionId: course.subscriptionId };
              if (course.subscriptionId) {
                tmpSubscriptions.push(dataItem);
              } else {
                tmpCourses.push(dataItem);
              }
            });
          }
          // for (let i = 0; i < 8; i++) {
          //   if(courses[i] !== 'undefined'){
          //     TREE_DATA[0].children.push({name: courses[i].courseName, parent: 'Courses', path: courses[i]._id})
          //   }
          // }
          TREE_DATA[0].children = tmpSubscriptions.concat(TREE_DATA[0].children);
          TREE_DATA[0].children = TREE_DATA[0].children.concat(tmpCourses);
          // TREE_DATA[0].children.push({ name: 'Show All Courses', parent: 'Courses' });
        }
        this.dataSource.data = TREE_DATA;

        if (this.loadingCount === 0) {
          this.dataSource.data.map((item) => {
            if (item.name === 'Courses') {
              this.selectedNode = item.name;
              if (item.children.length > 0) {
                this.activeChildNode = item.children[1]?.path;
              }
            }
          });
          this.loadingCount = 1;
        }
        // if(setNewCourse) {
        //   let routedCourseId = courses[courses.length - 1]._id
        //   this.router.navigate(['/my-curriculum'])
        //   this.updateCourse(routedCourseId)
        // }
      });
  }
  handleNode(node: TreeNode, courseIndex?: number) {
    this.selectedNode = node.parent;
    this.accountShow = false;
    this.activeChildNode = node.name;

    if (node.name === 'Support') {
      return;
    }

    if (node.parent === 'Courses' && !this.isActiveCourseItem(node)) {
      return;
    }
    if (node.name === 'Add a Course' && (this.selectedPlan !== 'Superhero' && this.selectedPlan !== 'Hero' && this.selectedPlan !== 'Demo')) {
      // this.activeChildNode = node.name;
      if (!this.router.url.includes('my-curriculum')) {
        this.router.navigateByUrl('/my-curriculum');
      }

      const dialogRef = this.dialog.open(AddCourseDialogComponent, {
        width: '250px',
        panelClass: 'add-course-modalbox',
        data: {
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        this.activeChildNode = this.activeCourse?._id;
      });

      dialogRef.componentInstance.functions = {
        reload: () => {
          this.getAllCourses();
          dialogRef.close();
        },
      };
    } else if (node.name === 'Show All Courses' && (this.selectedPlan !== 'Superhero' && this.selectedPlan !== 'Hero' && this.selectedPlan !== 'Demo')) {
      // this.activeChildNode = node.name;
      this.activeChildNode = this.activeCourse._id;
      this.showAllCourses = true;
      this.getAllCourses();
    } else {
      if (node.path !== undefined) {
        this.activeChildNode = node.path;
      }
      if (node.path === undefined) {
        this.activeChildNode = node.name;
        if ((node.parent === 'Help') && (this.activeChildNode === 'Tutorial Videos')) {
          this.currentUrl = '/my-curriculum';

          this.router.navigate(['/tutorials']);
          return;
        }
      }
      this.updateCourse(node.path);
      if (this.selectedPlan === 'Superhero' || this.selectedPlan === 'Hero' || this.selectedPlan === 'Demo') { return; }

      // NOTE: added a condition to avoid redirection. Navigation will be handled in ngrx effect
      if (node.parent !== 'Courses') {
        if ((this.path !== '/my-curriculum') && (this.activeChildNode !== 'Tutorial Videos')) { this.router.navigate(['/my-curriculum']); }
      }
    }
    // this.leftPanelShow = false;
  }
  goTosettings(node: TreeNode, e) {
    e.stopPropagation();
    ActivityHeaderService.updateOverDeadzone(true);
    this.selectedNode = node.parent;
    this.activeChildNode = node.name;
    this.activeParentNode = node.parent;


    // Already in the settings menu (this is necessary for the "Settings" icon in the activity header)
    if(this.router.url.split('/')[1] === 'settings') {
      this.router.navigate(['/settings', node.path]);
      this.accountShow = false;
      return;
    }

    if (( this.previousUrl?.includes('activity') || this.previousUrl?.includes('my-curriculum')) && this.path.includes('settings')) {
      localStorage.setItem('previousUrl', this.previousUrl);
      this.listnerService.setPreviousUrl(this.previousUrl);
    } else {
        this.previousUrl = this.currentUrl;
      this.currentUrl = "/" + node.path;
      localStorage.setItem('previousUrl',  this.previousUrl);
      this.listnerService.setPreviousUrl( this.previousUrl);

    }


    this.router.navigate(['/settings', node.path]);
    // this.leftPanelShow = true;
    this.accountShow = false;
  }
  goTosharing(node) {
    if (this.selectedPlan !== 'Demo') {
      this.activeParentNode = node.name;
      this.selectedNode = node.name;
      this.currentUrl = '/my-curriculum';
      // localStorage.setItem('previousUrl',  this.previousUrl);
      localStorage.setItem('create_collection', 'false');
      this.router.navigate(['/sharing']);
      this.accountShow = false;
    }
  }
  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;
  public logout() {
    this._guard.updateLoginStatus(false);
    AuthService.logout();
  }

  public updateCourse(courseId: string): void {
    const course = this.courses.find((c: any) => c._id === courseId);
    this.previousUrl = '/my-curriculum';
    // NOTE: subscriptionId needed for the delete
    // const subscriptionId = course.teacherKey === this.userId ? '' : ':' + course.subscriptionId;
    this.store.dispatch(new ClearCourseWorkbooks());
    this.store.dispatch(new SetCourseActions.SetCurriculumCourse({
      course,
      redirect: true
    }));

    // this.listnerService.callback(`course:${courseId}${subscriptionId}`);
    // this.courseService.currentCourse = this.courses.find((course) => course._id === courseId);
  }

  public iOS() {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document);
  }

  toggleNavBar() {
    // this.leftPanelShow = !this.leftPanelShow;
    if (this.leftPanelShow) {
      this.navbarListenerService.navbarCallback(false);
      this.indexExpanded = -1;
    } else {
      this.navbarListenerService.navbarCallback(true);
    }
    // this.activityHeaderService.navbarToggle.next(this.leftPanelShow);
    this.activityHeaderService.callNavbarListner();
    // this.sizeChange()
    this.listnerService.callback('navbar toggle');
    this.updateTilePosition();
  }
  toggleNavBarDisable() {
    this.leftPanelShow = true;
  }
  onNavBarOpen() {
    this.navbarListenerService.notifyNavbarOpened();
  }
  onNavBarClose() {
    this.navbarListenerService.notifyNavbarClosed();
  }

  goToAccount() {
    this.selectedNode = null;
    this.accountShow = true;
    this.currentUrl = '/my-curriculum';
    this.router.navigate(['/account']);
  }
  setShown() {
    if (this.shown === 'native') {
      this.shown = 'hover';
    } else if (this.shown === 'hover') {
      this.shown = 'always';
    } else {
      this.shown = 'native';
    }
  }

  setActiveNode(menuName: string, index: number) {
    // this.selectedNode = menuName;
    // this.leftPanelShow = false;
    this.accountShow = false;
    this.navbarListenerService.navbarCallback(false);
    this.indexExpanded = index;
    this.setChildActive();
  }

  gotoSettingsFromActivity(menuName: string) {
    if (menuName === 'Settings') {
      let activity = this.router.url.split('/')[2];
      if (!activity) return;

      let isInSettings = this.router.url.split('/')[1] === 'settings';
      if(isInSettings) {
        this.navbarListenerService.navbarCallback(false);
        return
      }

      localStorage.setItem('previousUrl', this.router.url);
      this.listnerService.setPreviousUrl(this.router.url);
      this.previousUrl = this.router.url;
      if (
        (activity.toLowerCase() === 'passages')
        ||
        (activity.toLowerCase() === 'sentences')
        ||
        (activity.toLowerCase() === 'phrases')
      ) {
        this.currentUrl = "/sentence-parts";
        this.router.navigate(['/settings', 'sentence-parts']);

      } else {
        this.currentUrl = "/" + activity.toLowerCase();
        this.router.navigate(['/settings', activity.toLowerCase()]);

      }
      // this.indexExpanded = -1;
    }
  }

  drop(event: CdkDragDrop<any[]>) {
    if (!TREE_DATA[0].children[event.currentIndex].subscriptionId && TREE_DATA[0].children[event.currentIndex].name !== 'Add a Course' && TREE_DATA[0].children[event.currentIndex].name !== 'Show All Courses') {
      moveItemInArray(TREE_DATA[0].children, event.previousIndex, event.currentIndex);
    }
    this.dataSource.data = TREE_DATA;
    const reOrderedCourses = JSON.parse(JSON.stringify(TREE_DATA[0].children));
    reOrderedCourses.splice(0, 1);
    if (reOrderedCourses[reOrderedCourses.length - 1].name === 'Show All Courses') {
      reOrderedCourses.splice(reOrderedCourses.length - 1, 1);
    }
    if (!TREE_DATA[0].children[event.currentIndex].subscriptionId && TREE_DATA[0].children[event.currentIndex].name !== 'Add a Course' && TREE_DATA[0].children[event.currentIndex].name !== 'Show All Courses') {
      this.changeCourseOrder(reOrderedCourses);
    }
  }

  changeCourseOrder(courses: any) {
    let reorderedCourses = JSON.parse(JSON.stringify(courses));
    reorderedCourses = courses
      .map((course, index) => {
        course.order = index;
        course._id = course.path;
        // course.teacherKey =
        delete course.path;
        return course;
      })
      .filter(course => course._id && !course.subscriptionId);

    const requests = reorderedCourses.map(course => this.courseService.update(course));
    combineLatest(requests)
      .pipe(take(1))
      .subscribe((result: any[]) => {
        reorderedCourses.forEach(course => {
          course.courseName = course.name;
          delete course.parent;
          delete course.name;
        });
        this.store.dispatch(new CoursesActions.ReorderCourses(reorderedCourses));
      });
  }

  setChildActive() {
    if (!this.path) {
      return;
    }

    // if (this.path.includes('settings') || this.path.includes('activity')) {
    if (this.path.includes('settings')) {
      this.path.includes('settings') ? this.selectedNode = 'Settings' : this.selectedNode = null;
      if (this.path.split("/")[2] === 'general') {
        this.activeChildNode = 'General';
      }
      if (this.path.split("/")[2] === 'tiles') {
        this.activeChildNode = 'Tile';
      }
      if (this.path.split("/")[2] === 'flashcards') {
        this.activeChildNode = 'Flashcards';
      }
      if (this.path.split("/")[2] === 'whiteboard') {
        this.activeChildNode = 'Whiteboard';
      }
      if (this.path.split("/")[2] === 'timed-reading') {
        this.activeChildNode = 'timed-reading';
      }
      if (this.path.split("/")[2] === 'wordcards') {
        this.activeChildNode = 'Wordcards';
      }
      if (this.path.split("/")[2] === 'lettercards') {
        this.activeChildNode = 'Lettercards';
      }
      if (this.path.split("/")[2] === 'blackboard') {
        this.activeChildNode = 'Blackboard';
      }
      if (this.path.split("/")[2] === 'workbook-tiles') {
        this.activeChildNode = 'workbook-tiles';
      }
      if (this.path.split("/")[2] === 'mini-tiles') {
        this.activeChildNode = 'mini-tiles';
      }
      if (this.path.split("/")[2] === 'spelling') {
        this.activeChildNode = 'Spelling';
      }
      if (this.path.split("/")[2] === 'passages') {
        this.activeChildNode = 'sentence-parts';
      }
      if (this.path.split("/")[2] === 'phrases') {
        this.activeChildNode = 'sentence-parts';
      }
      if (this.path.split("/")[2] === 'sentences') {
        this.activeChildNode = 'sentence-parts';
      }
      if (this.path.split("/")[2] === 'pdf-viewer') {
        this.activeChildNode = 'pdf-viewer';
      }
      if (this.path.split("/")[2] === 'blank-tiles') {
        this.activeChildNode = 'blank-tiles';
      }
    }

    if (this.path.includes('sharing')) {
      this.activeChildNode = 'Sharing';
      this.selectedNode = 'Sharing';
    }

    if (this.path.includes('tutorials')) {
      this.activeChildNode = 'Tutorial Videos';
      this.selectedNode = 'Help';
    }
  }
  logOutModal() {
    const dialogRef = this.dialog.open(LogOutModalComponent, {
      width: '350px',
      panelClass: ['modal-container', 'logout-modal'],
    });
  }
  selectPlanModal() {
    const dialogRef = this.dialog.open(SelectPlanModalComponent, {
      disableClose: true,
      width: '350px',
      panelClass: 'modal-container',
    });
  }
  demoReminderModal() {
    const dialogRef = this.dialog.open(DemoModalComponent, {
      width: '376px',
      panelClass: 'modal-container',
    });
  }

  ngAfterViewInit(): void {
    ActivityHeaderService.body = this.body;
    // localStorage.setItem('body', JSON.stringify(this.body))
    setTimeout(() => {
      this.checkHasScroll();
    }, 100);
  }

  toggleMouseEnter(b: boolean) {
    ActivityHeaderService.updateOverDeadzone(b);
  }

  updateTilePosition() {
    this.tileActivityService.setTilePositions();
  }

  toggleAccordionItem(hasScroll) {
    if (hasScroll) {
    } else {
    }
    this.hasScroll = hasScroll;
  }

  checkHasScroll() {
    const elem = setInterval(() => {
      if (this.matAccordion) {
        this.hasScroll = this.matAccordion.nativeElement.scrollHeight > this.matAccordion.nativeElement.clientHeight;
        clearInterval(elem);
      }

    }, 500);
  }

  checkUrlContains(text) {
    return this.router.url.includes(text);
  }

  // @HostListener('document:contextmenu', ['$event'])
  // onDocumentRightClick(event) {
  //   return false;
  // }

  isActiveCourseItem(node: TreeNode) {
    if (this.selectedPlan === 'Superhero Plus' || this.selectedPlan === 'Staff') {
      return true;
    }
    const courses = this.dataSource.data?.find(_ => _.name === 'Courses');
    const firstUnsubscriptionCourse = courses?.children.find(_ => !_.subscriptionId && _.name !== 'Add a Course');
    if (node.name === 'Add a Course') {
      if (firstUnsubscriptionCourse) {
        return false;
      } else {
        return true;
      }
    }

    if (!firstUnsubscriptionCourse) {
      return false;
    } else {
      return node.name === firstUnsubscriptionCourse.name;
    }
  }
}
@Component({
  selector: 'app-logout-modal',
  templateUrl: './logout-modal/logout-modal.html',
  styleUrls: ['./logout-modal/logout-modal.scss']
})
export class LogOutModalComponent {
  constructor(
    private _auth: AuthService,
  ) { }

  logOut() {
    AuthService.logout();
  }
}
@Component({
  selector: 'app-select-plan-modal',
  templateUrl: './select-plan-modal/select-plan-modal.html',
  styleUrls: ['./select-plan-modal/select-plan.modal.scss']
})
export class SelectPlanModalComponent {
  constructor(
    private router: Router,
    private _auth: AuthService,
    public dialogRef: MatDialogRef<SelectPlanModalComponent>

  ) { }

  goToPlanPage() {
    this.dialogRef.close();
    this.router.navigate(['/account']);
  }
  logOut() {
    this.dialogRef.close();
    AuthService.logout();
  }
}
@Component({
  selector: 'app-demo-modal',
  templateUrl: './demo-modal/demo-modal.html',
  styleUrls: ['./demo-modal/demo-modal.scss']
})
export class DemoModalComponent {
  constructor(
    private router: Router,
  ) { }

  goToPlanPage() {
    this.router.navigate(['/account']);
  }

}
