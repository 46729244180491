import { Action } from '@ngrx/store';
import { Accelerator } from 'electron';
import { CourseActionTypes } from '../courses/courses.actions';
import { ClearSubscriptions } from '../subscriptions/subscriptions.actions';

/***
 * For each type in an action group, make a simple
 * enum object for all of this group's action types.
 */

export enum CourseWorkbookActionTypes {
    GetCourseWorkbook = '[CourseWorkbookService] Get Course Workbook',
    GetCourseWorkbookSuccess = '[CourseWorkbookService] Get Course Workbook Success',
    GetCourseWorkbookFail = '[CourseWorkbookService] Get Course Workbook Fail',

    GetAllCourseWorkbooks = '[CourseWorkbookService] Get All Course Workbooks',
    GetAllCourseWorkbooksSuccess = '[CourseWorkbookService] Get All Course Workbooks Success',
    GetAllCourseWorkbooksFail = '[CourseWorkbookService] Get All Course Workbooks Fail',

    ClearCourseWorkbooks = '[CourseWorkbookService] Clear Course Workbooks',

    SetCurrentCourseWorkbook = '[Activity Page] Set Course Workbook',

    AddWorkbookToCourse = '[CourseWorkbookService] Add Workbook to Course',
    AddWorkbookToCourseSuccess = '[CourseWorkbookService] Add Workbook to Course  Success',

    AddWhizzimoWorkbookToCourse = '[CourseWorkbookService] Add Whizzimo Workbook to Course',
    AddWhizzimoWorkbookToCourseSuccess = '[CourseWorkbookService] Add Whizzimo Workbook to Course Success',

    RemoveWorkbookFromCourse = '[CourseWorkbookService] Remove Workbook to Course',
    RemoveWorkbookFromCourseSuccess = '[CourseWorkbookService] Remove Workbook to Course Success',

    RemoveWorkbookFromCourseViaLibrary = '[CourseWorkbookService] Remove Workbook to Course Via Library Page',
    RemoveWorkbookFromCourseViaLibrarySuccess = '[CourseWorkbookService] Remove Workbook to Course Via Library Page Success',

    CopyCourseWorkbook = '[CourseWorkbookService] Copy Course Workbook',
    CopyCourseWorkbookSuccess = '[CourseWorkbookService] Copy Course Workbook Success',

    CreateCourseWorkbook = '[CourseWorkbookService] Create Course Workbook',
    CreateCourseWorkbookSuccess = '[CourseWorkbookService] Create Course Workbook Success',

    RefreshCourseWorkbooksList = '[CourseWorkbookService] Refresh Course Workbooks List',
    RefreshCourseWorkbooksListSuccess = '[CourseWorkbookService] Refresh Course Workbooks List Success',

    UpdateByWorkbook = '[CourseWorkbookService] Update Course Workbook',
    UpdateByWorkbookSuccess = '[CourseWorkbookService] Update Course Workbook Success',
    UpdateByWorkbookFail = '[CourseWorkbookService] Update Course Workbook Fail',
}

/***
 * Every action is comprised of at least a type and an optional
 * payload. Expressing actions as classes enables powerful
 * type checking in reducer functions
 */
export class GetCourseWorkbook implements Action {
    readonly type = CourseWorkbookActionTypes.GetCourseWorkbook;

    constructor(public payload: any) {}
}

export class GetCourseWorkbookSuccess implements Action {
    readonly type = CourseWorkbookActionTypes.GetCourseWorkbookSuccess;

    constructor(public payload: any) {}
}
export class GetCourseWorkbookFail implements Action {
    readonly type = CourseWorkbookActionTypes.GetCourseWorkbookFail;

    constructor(public payload: any) {}
}

export class GetAllCourseWorkbooks implements Action {
    readonly type = CourseWorkbookActionTypes.GetAllCourseWorkbooks;

    constructor(public payload: any) {}
}

export class GetAllCourseWorkbooksSuccess implements Action {
    readonly type = CourseWorkbookActionTypes.GetAllCourseWorkbooksSuccess;

    constructor(public payload: any) {}
}

export class GetAllCourseWorkbooksFail implements Action {
    readonly type = CourseWorkbookActionTypes.GetAllCourseWorkbooksFail;

    constructor(public payload: any) {}
}

export class ClearCourseWorkbooks implements Action {
    readonly type = CourseWorkbookActionTypes.ClearCourseWorkbooks;
}

export class SetCurrentCourseWorkbook implements Action {
    readonly type = CourseWorkbookActionTypes.SetCurrentCourseWorkbook;

    constructor(public payload: string) {
    }
}

export class AddWorkbookToCourse implements Action {
    readonly type = CourseWorkbookActionTypes.AddWorkbookToCourse;

    constructor(public payload: any) {
    }
}

export class AddWorkbookToCourseSuccess implements Action {
    readonly type = CourseWorkbookActionTypes.AddWorkbookToCourseSuccess;

    constructor(public payload: any) {
    }
}

export class AddWhizzimoWorkbookToCourse implements Action {
    readonly type = CourseWorkbookActionTypes.AddWhizzimoWorkbookToCourse;

    constructor(public payload: any) {
    }
}

export class AddWhizzimoWorkbookToCourseSuccess implements Action {
    readonly type = CourseWorkbookActionTypes.AddWhizzimoWorkbookToCourseSuccess;

    constructor(public payload: any) {
    }
}
export class RemoveWorkbookFromCourse implements Action {
    readonly type = CourseWorkbookActionTypes.RemoveWorkbookFromCourse;

    constructor(public payload: string) {
    }
}

export class RemoveWorkbookFromCourseSuccess implements Action {
    readonly type = CourseWorkbookActionTypes.RemoveWorkbookFromCourseSuccess;

    constructor(public payload: string) {
    }
}

export class RemoveWorkbookFromCourseViaLibrary implements Action {
    readonly type = CourseWorkbookActionTypes.RemoveWorkbookFromCourseViaLibrary;

    constructor(public payload: any) {
    }
}

export class RemoveWorkbookFromCourseViaLibrarySuccess implements Action {
    readonly type = CourseWorkbookActionTypes.RemoveWorkbookFromCourseViaLibrarySuccess;

    constructor(public payload: any) {
    }
}

export class CopyCourseWorkbook implements Action {
    readonly type = CourseWorkbookActionTypes.CopyCourseWorkbook;

    constructor(public payload: any) {
    }
}

export class CopyCourseWorkbookSuccess implements Action {
    readonly type = CourseWorkbookActionTypes.CopyCourseWorkbookSuccess;

    constructor(public payload: any) {
    }
}

export class CreateCourseWorkbook implements Action {
    readonly type = CourseWorkbookActionTypes.CreateCourseWorkbook;

    constructor(public payload: any) {
    }
}

export class CreateCourseWorkbookSuccess implements Action {
    readonly type = CourseWorkbookActionTypes.CreateCourseWorkbookSuccess;

    constructor(public payload: any) {
    }
}

export class RefreshCourseWorkbooksList implements Action {
    readonly type = CourseWorkbookActionTypes.RefreshCourseWorkbooksList;

    constructor(public payload: any) {
    }
}

export class RefreshCourseWorkbooksListSuccess implements Action {
    readonly type = CourseWorkbookActionTypes.RefreshCourseWorkbooksListSuccess;

    constructor(public payload: any) {
    }
}

export class UpdateByWorkbook implements Action {
    readonly type = CourseWorkbookActionTypes.UpdateByWorkbook;

    constructor(public payload: any) {}
}

export class UpdateByWorkbookSuccess implements Action {
    readonly type = CourseWorkbookActionTypes.UpdateByWorkbookSuccess;

    constructor(public payload: any) {}
}
export class UpdateByWorkbookFail implements Action {
    readonly type = CourseWorkbookActionTypes.UpdateByWorkbookFail;

    constructor(public payload: any) {}
}


export type CourseWorkbookAction
= GetCourseWorkbook
| GetCourseWorkbookSuccess
| GetCourseWorkbookFail
| GetAllCourseWorkbooks
| GetAllCourseWorkbooksSuccess
| GetAllCourseWorkbooksFail
| ClearCourseWorkbooks
| SetCurrentCourseWorkbook
| AddWorkbookToCourse
| AddWorkbookToCourseSuccess
| RemoveWorkbookFromCourse
| RemoveWorkbookFromCourseSuccess
| AddWhizzimoWorkbookToCourse
| AddWhizzimoWorkbookToCourseSuccess
| RemoveWorkbookFromCourseViaLibrary
| RemoveWorkbookFromCourseViaLibrarySuccess
| CopyCourseWorkbook
| CopyCourseWorkbookSuccess
| CreateCourseWorkbook
| CreateCourseWorkbookSuccess
| RefreshCourseWorkbooksList
| RefreshCourseWorkbooksListSuccess
| UpdateByWorkbook
| UpdateByWorkbookSuccess
| UpdateByWorkbookFail;
